import $ from 'jquery';
import * as GOVUKFrontend from 'govuk-frontend';
import * as MOJFrontend from '@ministryofjustice/frontend';
import * as Calendar from './calendar';
import * as WniInNl from './wni-in-nl';
window.$ = $;
window.GOVUKFrontend = GOVUKFrontend;
window.MOJFrontend = MOJFrontend;

require('hmpo-components/all');

window.GOVUKFrontend.initAll();
window.MOJFrontend.initAll();
Calendar.initAll();
window.WniInNl = {
  init: WniInNl.init,
};
