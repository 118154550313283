import { Calendar } from '@fullcalendar/core';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import dayGridPlugin from '@fullcalendar/daygrid';
import idLocale from '@fullcalendar/core/locales/id';

function init(element, { eventSources, events } = {}) {
  eventSources = eventSources || [];
  events = events || {};

  new Calendar(element, {
    locale: idLocale,
    plugins: [googleCalendarPlugin, dayGridPlugin],
    initialView: 'dayGridMonth',
    nowIndicator: true,
    events,
    eventSources,
    googleCalendarApiKey: process.env.GOOGLE_CALENDAR_API_KEY,
    buttonText: {
      today: 'Hari ini',
      prev: 'Mundur',
      next: 'Maju',
    },
    headerToolbar: {
      left: 'title',
      right: 'prev,next,dayGridMonth,dayGridWeek',
    },
  }).render();
}

export function initAll() {
  const ambassadorCalendar = document.querySelector(
    '[data-module="agenda-ambassador"]'
  );

  if (ambassadorCalendar) {
    init(ambassadorCalendar, {
      events: {
        googleCalendarId: process.env.GOOGLE_CALENDAR_ID_AMBASSADOR,
      },
    });
  }

  const dcmCalendar = document.querySelector(
    '[data-module="agenda-deputy-chief-of-mission"]'
  );

  if (dcmCalendar) {
    init(dcmCalendar, {
      events: {
        googleCalendarId: process.env.GOOGLE_CALENDAR_ID_DCM,
      },
    });
  }
}
