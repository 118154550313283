import * as d3 from 'd3';
import * as topojson from 'topojson-client';

export async function init({
  container,
  wniInNlData,
  wniInNlDataTranslations,
}) {
  if (!wniInNlData) {
    return;
  }

  const data = JSON.parse(wniInNlData);
  const translations = JSON.parse(wniInNlDataTranslations);

  const width = 700;
  const height = 700;

  const svg = d3
    .select(container)
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('style', 'max-width: 100%; height: auto;');

  const nl = await d3.json(
    'https://cartomap.github.io/nl/wgs84/provincie_2022.topojson'
  );

  const projection = d3.geoMercator().scale(1).translate([0, 0]);

  projection.fitSize(
    [width, height],
    topojson.feature(nl, nl.objects.provincie_2022)
  );

  const path = d3.geoPath().projection(projection);

  const valuemap = new Map(
    data.map((datum) => [datum.provinceCode, datum.value])
  );

  const outerBorder = topojson.mesh(
    nl,
    nl.objects.provincie_2022,
    (a, b) => a === b
  );

  const color = d3
    .scaleQuantize()
    .domain(d3.extent(data, (d) => d.value))
    .range(d3.schemeBlues[9]);

  svg
    .selectAll('path')
    .data(topojson.feature(nl, nl.objects.provincie_2022).features)
    .join('path')
    .attr('fill', (d) => color(valuemap.get(d.id)))
    .attr('d', path)
    .style('stroke', 'white')
    .style('stroke-width', '2');

  svg
    .append('path')
    .datum(outerBorder)
    .attr('fill', 'none')
    .attr('stroke', 'black')
    .attr('stroke-width', '1')
    .attr('stroke-linejoin', 'round')
    .attr('d', path);

  const tooltip = d3
    .select(container)
    .append('div')
    .attr('class', 'app-wni-in-nl-tooltip')
    .html(`<div class="app-wni-in-nl-tooltip__header"></div>`);

  svg
    .selectAll('path')
    .on('mouseover', (_event, d) => {
      const provinceCode = d.id;

      if (!provinceCode) {
        return;
      }

      const provinceName = translations[provinceCode];
      const totalPercentage = valuemap.get(provinceCode);
      tooltip
        .select('.app-wni-in-nl-tooltip__header')
        .text(`${provinceName} - ${totalPercentage}%`);
      return tooltip.style('visibility', 'visible');
    })
    .on('mousemove', function (event) {
      const container = window.$(this);
      const relativeParent = container.closest('.govuk-grid-row');

      const distanceOffsetY = event.pageY - relativeParent.position().top;
      const distanceOffsetX = event.pageX - relativeParent.position().left;

      return tooltip
        .style('left', distanceOffsetX + 15 + 'px')
        .style('top', distanceOffsetY + 5 + 'px');
    })
    .on('mouseout', function () {
      return tooltip.style('visibility', 'hidden');
    });
}
